import React, { FC } from 'react';

import { Box } from '@mui/material';

import { styles } from './styles';
import LogoIcon from '@components/icons/Logo';
import { Link } from 'react-router-dom';
import { book } from '@routes/book';

type LogoProps = {};

export const Logo: FC<LogoProps> = () => {
  return (
    <Link to={book.home} style={{ textDecoration: 'none' }}>
      <Box sx={styles.logo}>
        <LogoIcon sx={styles.icon} />
      </Box>
    </Link>
  );
};

export default Logo;
