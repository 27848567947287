import React, { FC } from 'react';

import { Button, Typography, useTheme } from '@mui/material';

import { styles } from './styles';
import { book } from '@routes/book';
import { storyTellerActions } from '@bus/storyTeller/actions';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCanDoStoryAction,
  getProfile,
  getShowBuyTokensStoryModal,
  getShowStoryUpdatePlanToUseTokensModal,
} from '@bus/profile/selectors';
import { getStories } from '@bus/storyTeller/selectors';
import AddCircleOutline from '@components/icons/AddCircleOutline';
import { useLimits } from '../../../hooks/useLimits';

type CreateNewStoryProps = {};

export const CreateNewStory: FC<CreateNewStoryProps> = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profile = useSelector(getProfile);
  const stories = useSelector(getStories);

  const canDoAction = useSelector(getCanDoStoryAction);
  const showBuyTokensModal = useSelector(getShowBuyTokensStoryModal);
  const showUpdatePlanToUseTokensModal = useSelector(
    getShowStoryUpdatePlanToUseTokensModal,
  );

  const { handleLimitExceeded } = useLimits(
    showUpdatePlanToUseTokensModal,
    showBuyTokensModal,
    'text_line_3',
  );

  const handleCreateNewChat = () => {
    if (!profile?.subscription) {
      navigate(book.forUnsubscribedUsers);

      return;
    }

    if (!canDoAction) {
      handleLimitExceeded();

      return;
    }

    new Promise((resolve, reject) => {
      dispatch(
        storyTellerActions.createStoryChat({
          values: {
            project: stories?.id,
            name: 'New story',
          },
          resolve,
          reject,
          navigate,
        }),
      );
    })
      .then()
      .catch();
  };

  return (
    <Button
      sx={styles.createNewStory}
      variant={'secondary'}
      color={'secondary'}
      onClick={handleCreateNewChat}
      startIcon={
        <AddCircleOutline
          sx={{ fontSize: '20px' }}
          htmlColor={theme.palette.common.surface['surface 47']}
        />
      }>
      <Typography variant={'body1'} color={'text.secondary'}>
        New story
      </Typography>
    </Button>
  );
};

export default CreateNewStory;
