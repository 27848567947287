import React, { FC } from 'react';

import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { styles } from './styles';
import Logo from '@components/common/Logo';
import { useSelector } from 'react-redux';
import { getProfile } from '@bus/profile/selectors';
import { Tooltip } from '@components/common/uiKit/Tooltip';
import AppBarActions from '@components/common/AppBarActions';
import { shortTokensAmount } from '@helpers/shortTokensAmount';
import Payments from '@components/icons/Payments';
import User from '@components/icons/User';
import TotalCreate from '@components/common/uiKit/Tooltip/TotalCreate';
import CustomDrawer from '@components/common/uiKit/CustomDrawer';
import ProfileDrawer from '@components/drawers/ProfileDrawer';

type AppBarProps = {
  authorized?: boolean;
  isBackground?: boolean;
  buttonProps?: {
    icon: React.ReactNode;
    label: string;
    onClick: () => void;
  };
  secondButtonProps?: {
    icon: React.ReactNode;
    label: string;
    onClick: () => void;
  } | null;
  drawerContent?: React.ElementType;
  secondDrawerContent?: React.ElementType;
  rightAdditionalContent?: React.ElementType;
};

export const AppBar: FC<AppBarProps> = ({
  authorized = true,
  isBackground = true,
  buttonProps = null,
  drawerContent,
  secondButtonProps = null,
  secondDrawerContent,
  rightAdditionalContent = null,
}) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down('md'));
  const profile = useSelector(getProfile);
  const [state, setState] = React.useState({
    left: false,
    secondLeft: false,
    right: false,
  });

  const toggleDrawer =
    (anchor: string, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  return (
    <>
      <Box sx={(theme) => styles.appBar(theme, isBackground)}>
        <Box sx={styles.container}>
          <Box sx={styles.leftSide}>
            <Logo />
            {buttonProps && (
              <Button
                variant={'drawer'}
                startIcon={buttonProps.icon}
                onClick={(event) => {
                  toggleDrawer('left', true)(event);
                  buttonProps.onClick();
                }}
                sx={(theme) =>
                  styles.historyButton(theme, !!secondButtonProps)
                }>
                <Typography variant={'body1'} color={'text.secondary'}>
                  {buttonProps.label}
                </Typography>
              </Button>
            )}
            {secondButtonProps && (
              <Button
                variant={'drawer'}
                startIcon={secondButtonProps.icon}
                onClick={(event) => {
                  toggleDrawer('secondLeft', true)(event);
                  secondButtonProps.onClick();
                }}
                sx={styles.secondLeftButton}>
                <Typography variant={'body1'} color={'text.secondary'}>
                  {secondButtonProps.label}
                </Typography>
              </Button>
            )}
            {authorized && (
              <Box sx={styles.appAction}>
                <AppBarActions
                  isHaveButtons={!!buttonProps || !!secondButtonProps}
                />
              </Box>
            )}
          </Box>
          <Box sx={styles.wrapper}>
            {authorized && (
              <Box sx={styles.menuWrapper}>
                {rightAdditionalContent &&
                  !isMd &&
                  React.createElement(rightAdditionalContent)}
                <Button
                  startIcon={
                    <User
                      sx={{ fontSize: '20px' }}
                      htmlColor={theme.palette.common.surface['surface 47']}
                    />
                  }
                  variant={'drawer'}
                  sx={styles.button}
                  onClick={toggleDrawer('right', true)}>
                  <Typography variant={'body1'} color={'text.secondary'}>
                    Profile
                  </Typography>
                </Button>
                <Tooltip
                  title={<TotalCreate tokens={profile?.tokens} />}
                  isBackground={false}
                  arrow={false}
                  mobileCenter
                  zIndex={10000}
                  placement={'bottom'}
                  id={'app-bar-tokens'}
                  open={isMd}>
                  <Button
                    variant={'drawer'}
                    sx={styles.tokens}
                    endIcon={
                      <Payments
                        sx={{ fontSize: '20px' }}
                        htmlColor={theme.palette.common.surface['surface 47']}
                      />
                    }>
                    <Typography variant={'body1'} color={'text.secondary'}>
                      {shortTokensAmount(profile?.tokens ?? 0, true, false)}
                    </Typography>
                    <Typography
                      variant={'body1'}
                      color={'text.secondary'}
                      sx={styles.tokenLabel}>
                      CREATE
                    </Typography>
                  </Button>
                </Tooltip>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <CustomDrawer
        anchor={'left'}
        open={state['left']}
        onClose={toggleDrawer('left', false)}>
        {drawerContent &&
          React.createElement(drawerContent, {
            onClose: toggleDrawer('left', false),
          })}
      </CustomDrawer>
      <CustomDrawer
        anchor={'left'}
        open={state['secondLeft']}
        onClose={toggleDrawer('secondLeft', false)}>
        {secondDrawerContent &&
          React.createElement(secondDrawerContent, {
            onClose: toggleDrawer('secondLeft', false),
          })}
      </CustomDrawer>
      <CustomDrawer
        anchor={'right'}
        open={state['right']}
        onClose={toggleDrawer('right', false)}>
        <ProfileDrawer onClose={toggleDrawer('right', false)} />
      </CustomDrawer>
    </>
  );
};

export default AppBar;
