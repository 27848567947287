import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { Box, useTheme } from '@mui/material';

import { styles } from './styles';
import { useCurrentDataset } from '../../hooks/useCurrentDataset';
import History from '@components/icons/History';
import ChatHistoryDrawer from '@components/drawers/ChatHistoryDrawer';
import AppBar from '@components/common/AppBar';
import { registeredModals } from '@components/modals/registeredModals';
import { Modals } from '@core/Modal';
import SuspenseLoading from '@components/common/SuspenseLoading';
import ChatOutline from '@components/icons/ChatOutline';
import ChatGuidanceDrawer from '@components/drawers/ChatGuidanceDrawer';

export type ChatLayoutProps = {};

const ChatLayout: React.FC<ChatLayoutProps> = () => {
  const theme = useTheme();
  useCurrentDataset();

  return (
    <Box sx={styles.root}>
      <AppBar
        buttonProps={{
          icon: (
            <History
              sx={{ fontSize: '20px' }}
              htmlColor={theme.palette.common.surface['surface 47']}
            />
          ),
          onClick: () => {},
          label: 'History',
        }}
        secondButtonProps={{
          icon: (
            <ChatOutline
              sx={{ fontSize: '20px' }}
              htmlColor={theme.palette.common.surface['surface 47']}
            />
          ),
          onClick: () => {},
          label: 'Guidance',
        }}
        drawerContent={ChatHistoryDrawer}
        secondDrawerContent={ChatGuidanceDrawer}
      />
      <Modals registeredModals={registeredModals} />
      <Suspense fallback={<SuspenseLoading />}>
        <Box sx={styles.wrapper}>
          <Outlet />
        </Box>
      </Suspense>
    </Box>
  );
};

export default ChatLayout;
