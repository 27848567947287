import React, { useEffect, useState } from 'react';

import { Box, ModalProps, Skeleton, TextField } from '@mui/material';

import { ModalTypes } from '@core/Modal/types';

import { styles } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { chatActions } from '@bus/chat/actions';
import { getFoundedChats } from '@bus/chat/selectors';
import { useNavigate } from 'react-router-dom';
import { book } from '@routes/book';
import SearchChatList from '@components/common/SearchChatList';
import { v4 as uuidv4 } from 'uuid';

type SearchChatProps = {
  text: string;
  onClose?: ModalProps['onClose'];
};

export const SearchChat: React.FC<
  ModalTypes.ModalComponentProps<SearchChatProps>
> = ({ closeFn, onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [value, setValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const foundedChats = useSelector(getFoundedChats);

  const closeModal = () => {
    closeFn();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    if (e.target.value.length < 3) {
      dispatch(chatActions.clearFoundedChat());

      return;
    }
    setIsLoading(true);
    new Promise((resolve, reject) => {
      dispatch(
        chatActions.searchChat({
          values: { query: e.target.value },
          resolve,
          reject,
        }),
      );
    })
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const handleNavigate = (
    event: React.KeyboardEvent | React.MouseEvent,
    name: string,
    id: number,
  ) => {
    onClose?.(event, 'backdropClick');
    closeModal();
    navigate(`${book.chat}/${name}/${id}`);
    dispatch(chatActions.clearFoundedChat());
  };

  useEffect(() => {
    return () => {
      dispatch(chatActions.clearFoundedChat());
    };
  }, []);

  return (
    <Box sx={styles.searchChat}>
      <TextField
        sx={styles.input}
        inputProps={{
          'data-testid': 'send-message-message',
        }}
        placeholder={'Search chats'}
        required
        fullWidth
        value={value}
        onChange={handleChange}
      />
      {isLoading && (
        <Box sx={styles.list}>
          {[...Array(6)].map(() => (
            <Box
              key={uuidv4()}
              display={'flex'}
              flexDirection={'column'}
              gap={'10px'}
              p={'12px 20px'}>
              <Skeleton variant={'rounded'} width={'100%'} height={'24px'} />
              <Skeleton variant={'rounded'} width={'100%'} height={'16px'} />
            </Box>
          ))}
        </Box>
      )}
      {!isLoading && foundedChats && (
        <SearchChatList
          foundedChats={foundedChats}
          handleNavigate={handleNavigate}
        />
      )}
    </Box>
  );
};

export default SearchChat;
