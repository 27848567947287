import * as effects from 'redux-saga/effects';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';

import { chatActions } from '../../actions';
import {
  FillFoundedChatActionPayload,
  SearchChatActionPayload,
} from '../../typedefs';
import { PayloadActionWithPromiseMeta } from '@core/FinalForm/typedefs';
import { getEndpointParams } from '@helpers/getEndpointParams';

export function* searchChat({
  payload,
  meta: { resolve, reject },
}: PayloadActionWithPromiseMeta<SearchChatActionPayload>) {
  const query = getEndpointParams(
    {
      ...payload,
    },
    new URLSearchParams(),
  );
  try {
    yield effects.put(chatActions.startFetching());
    const response: Response = yield effects.apply(api, api.get, [
      { endpoint: 'chats/search/', query },
    ]);
    if (!response.ok) {
      reject();
      yield throwError(response);
    }

    const data: FillFoundedChatActionPayload = yield effects.call([
      response,
      'json',
    ]);
    yield effects.put(chatActions.fillFoundedChat(data));

    resolve();
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(chatActions.stopFetching());
  }
}
