import * as effects from 'redux-saga/effects';

import { chatActions } from '../actions';
// eslint-disable-next-line prettier/prettier
import {
  fetchMessages,
  loadMoreMessages,
  debounceChat,
  loadPrevMessages,
  searchChat,
} from './workers';

// IMPORTS
function* watchFetchMessages() {
  yield effects.takeEvery(chatActions.fetchMessages.type, fetchMessages);
}
function* watchLoadMoreMessages() {
  yield effects.takeEvery(chatActions.loadMoreMessages.type, loadMoreMessages);
}
function* watchDebounceChat() {
  yield effects.takeLatest(chatActions.debounceChat.type, debounceChat);
}
function* watchLoadPrevMessages() {
  yield effects.takeEvery(chatActions.loadPrevMessages.type, loadPrevMessages);
}
function* watchSearchChat() {
  yield effects.takeEvery(chatActions.searchChat.type, searchChat);
}
// WATCHERS
export function* watchChat() {
  // eslint-disable-next-line prettier/prettier
  yield effects.all([
    effects.call(watchFetchMessages),
    effects.call(watchLoadMoreMessages),
    effects.call(watchDebounceChat),
    effects.call(watchLoadPrevMessages),
    effects.call(watchSearchChat),
    // INJECT
  ]);
}
