import React, { FC } from 'react';

import {
  Box,
  Button,
  CircularProgress,
  Typography,
  useTheme,
} from '@mui/material';

import { styles } from './styles';
import { BillingStatus } from '@bus/subscription/typedefs';
import Logo from '@components/icons/Logo';
import Close from '@components/icons/Close';
import Done from '@components/icons/Done';
import { useDispatch, useSelector } from 'react-redux';
import { subscriptionActions } from '@bus/subscription/actions';
import { getIsApprovedOrder } from '@bus/ws/selectors';
import { wsActions } from '@bus/ws/actions';
import { useSnackbar } from 'notistack';

type SubscriptionBillingLoadingProps = {
  status: string;
};

export const SubscriptionBillingLoading: FC<
  SubscriptionBillingLoadingProps
> = ({ status }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isApprovedOrder = useSelector(getIsApprovedOrder);
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = () => {
    if (isApprovedOrder) {
      dispatch(wsActions.setApprovedOrder(true));
      enqueueSnackbar(
        `Tokens successfully purchased! Remember, they're usable only with an active subscription. Enjoy uninterrupted access!`,
        {
          variant: 'infoSnackbar',
          autoHideDuration: 10000,
        },
      );
    }
    dispatch(subscriptionActions.setBillingStatus(null));
  };

  if (status === BillingStatus.processing) {
    return (
      <Box sx={styles.subscriptionBillingLoading}>
        <Box sx={styles.wrapper}>
          <CircularProgress sx={styles.progress} thickness={1} size={80} />
          <Logo sx={styles.icon} />
        </Box>
        <Typography variant={'body1'} my={'20px'} color={'text.primary'}>
          Payment processing...
        </Typography>
        <Typography
          variant={'body1'}
          color={
            'text.disabled'
          }>{`Your payment is being processed.`}</Typography>
        <Typography variant={'body1'} color={'text.disabled'}>
          Stay tuned for your journey to unfold.
        </Typography>
      </Box>
    );
  }

  if (status === BillingStatus.error) {
    return (
      <Box sx={styles.subscriptionBillingLoading}>
        <Box sx={styles.errorWrapper}>
          <Close fontSize={'small'} htmlColor={theme.palette.error.main} />
        </Box>
        <Typography variant={'body1'} my={'20px'} color={'text.primary'}>
          Oops! Something went wrong
        </Typography>
        <Typography
          variant={'subtitle1'}
          color={'text.disabled'}>{`Payment hiccup.`}</Typography>
        <Typography variant={'body1'} color={'text.disabled'} mb={'20px'}>
          Double-check details or try another method.
        </Typography>
        <Button variant={'primary'} color={'sixth'} onClick={handleClick}>
          <Typography variant={'body1'}>Go to your profile</Typography>
        </Button>
      </Box>
    );
  }

  if (status === BillingStatus.success) {
    return (
      <Box sx={styles.subscriptionBillingLoading}>
        <Box sx={styles.successWrapper}>
          <Done fontSize={'small'} htmlColor={theme.palette.success.main} />
        </Box>
        <Typography variant={'body1'} my={'20px'} color={'text.primary'}>
          Success!
        </Typography>
        <Typography
          variant={'body1'}
          color={
            'text.disabled'
          }>{`Congratulations! Payment successful.`}</Typography>
        <Typography variant={'body1'} color={'text.disabled'} mb={'20px'}>
          Get ready to embrace wisdom and enlightenment.
        </Typography>
        <Button variant={'primary'} color={'sixth'} onClick={handleClick}>
          <Typography variant={'body1'}>Go to your profile</Typography>
        </Button>
      </Box>
    );
  }

  return null;
};

export default SubscriptionBillingLoading;
