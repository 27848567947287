import React, { FC, useEffect, useRef, useState } from 'react';

import { IMessageAudio } from '@bus/aiConversation/typedefs';
import { IconButton, useTheme } from '@mui/material';
import VolumeUp from '@components/icons/VolumeUp';
import { handleErrors } from '@helpers/handleErrors';
import silence from '@assets/silence.mp3';
import { useDispatch, useSelector } from 'react-redux';
import { aiConversationActions } from '@bus/aiConversation/actions';
import {
  getIsAudioFetching,
  getIsAudioPlay,
  getIsPlaying,
  getIsPlayingWaiting,
  getIsSessionLive,
  getNextSession,
  getStartIndex,
} from '@bus/aiConversation/selectors';
import { useParams } from 'react-router-dom';
import GotItTooltip from '@components/common/uiKit/Tooltip/GotItTooltip';
import Tooltip from '@components/common/uiKit/Tooltip';
import Sound from '@components/icons/Sound';
import Pause from '@components/icons/Pause';
import { styles } from './styles';

export interface IAudioPlayerProps {
  id: number | string;
  audios: IMessageAudio[];
}

type AudioPlayerProps = {
  audioGroups: IAudioPlayerProps[];
};

export const AudioPlayer: FC<AudioPlayerProps> = ({ audioGroups }) => {
  const [groupIndex, setGroupIndex] = useState(0);
  const [audioIndex, setAudioIndex] = useState(0);
  const [queue, setQueue] = useState<IAudioPlayerProps[]>([]);
  const audioRef = useRef<HTMLAudioElement>(null);
  const theme = useTheme();
  const dispatch = useDispatch();
  const isPlayingWaiting = useSelector(getIsPlayingWaiting);
  const isPlaying = useSelector(getIsAudioPlay);
  const nextSession = useSelector(getNextSession);
  const isAudioMessagePlaying = useSelector(getIsPlaying);
  const isAudioMessageFetching = useSelector(getIsAudioFetching);
  const { year, month, sessionId } = useParams();
  const isSessionLive = useSelector(getIsSessionLive(year, month, sessionId));
  const localSessionId = window.localStorage?.getItem('isSessionProcessing');
  const messageStartIndex = useSelector(getStartIndex);

  useEffect(() => {
    if (isPlaying) {
      dispatch(aiConversationActions.stopAudioPlaying());
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        resetAudioState();
      }
    }
    setQueue([]);
    setGroupIndex(0);
    setAudioIndex(0);
    dispatch(aiConversationActions.stopPlayingWaiting());
  }, [sessionId, messageStartIndex]);

  useEffect(() => {
    if (messageStartIndex !== null && messageStartIndex !== 0) {
      const index = audioGroups.findIndex(
        (audio) => audio.id === messageStartIndex,
      );
      setQueue(audioGroups.slice(index + 1));
      setGroupIndex(0);
      setAudioIndex(0);
    }
  }, [audioGroups, messageStartIndex]);

  useEffect(() => {
    if (messageStartIndex === null) {
      setQueue((prevQueue) => {
        const newGroups = audioGroups.slice(prevQueue.length);

        return [...prevQueue, ...newGroups];
      });
    }
  }, [audioGroups]);

  useEffect(() => {
    if (isPlaying && audioRef.current) {
      audioRef.current.play().catch((error) => {
        handleErrors(`Error playing audio: ${error}`);
      });
    }
  }, [queue, audioIndex, isPlaying, isPlayingWaiting, groupIndex]);

  useEffect(() => {
    if (queue.length > 0 && isPlayingWaiting && isPlaying) {
      dispatch(aiConversationActions.stopPlayingWaiting());
      dispatch(aiConversationActions.startAudioPlaying());
    }
    if (audioGroups.length === 0) {
      dispatch(aiConversationActions.startPlayingWaiting());
    }
  }, [queue]);

  const playNext = () => {
    if (queue.length === 0) {
      dispatch(aiConversationActions.startPlayingWaiting());

      return;
    }

    if (audioIndex < queue[groupIndex]?.audios?.length - 1) {
      setAudioIndex((prevIndex) => prevIndex + 1);

      return;
    }

    handleEndOfGroup();
  };

  const handleEndOfGroup = () => {
    if (groupIndex < queue.length - 1) {
      setGroupIndex((prevGroupIndex) => prevGroupIndex + 1);
      setAudioIndex(0);

      return;
    }

    handleEndOfAllAudios();
  };

  const handleEndOfAllAudios = () => {
    dispatch(aiConversationActions.stopAudioPlaying());
    handleWaitingAudio();
    resetAudioState();
  };

  const resetAudioState = () => {
    setQueue([]);
    setGroupIndex(0);
    setAudioIndex(0);
  };

  const handleWaitingAudio = () => {
    if (isPlayingWaiting) {
      replayAudio();
    } else if (queue.length === 1 && isPlaying) {
      dispatch(aiConversationActions.startPlayingWaiting());
      dispatch(aiConversationActions.startAudioPlaying());
    }
  };

  const replayAudio = () => {
    if (audioRef.current) {
      audioRef.current.currentTime = 0;
      audioRef.current.play().catch((error) => {
        handleErrors(`Error replaying load: ${error}`);
      });
    }
  };

  const handleAudioPlay = () => {
    if (isPlaying) {
      dispatch(aiConversationActions.stopAudioPlaying());
      if (isSessionLive || localSessionId === sessionId) {
        resetAudioState();
        dispatch(aiConversationActions.startPlayingWaiting());
      }
      if (audioRef.current) {
        audioRef.current.pause();
        (isSessionLive || localSessionId === sessionId) &&
          (audioRef.current.currentTime = 0);
      }
    } else {
      dispatch(aiConversationActions.startAudioPlaying());
    }
  };

  return (
    <>
      <audio
        ref={audioRef}
        src={
          isPlayingWaiting && queue.length === 0
            ? silence
            : queue[groupIndex]?.audios?.[audioIndex]?.audio
        }
        onEnded={playNext}>
        <track kind={'captions'} />
      </audio>
      <Tooltip
        title={
          <GotItTooltip
            text={'Elevate Your Experience with Audio Insights'}
            buttonText={'Listen'}
            onClick={() => dispatch(aiConversationActions.startAudioPlaying())}
            icon={
              <VolumeUp
                fontSize={'small'}
                htmlColor={theme.palette.text.primary}
              />
            }
          />
        }
        zIndex={10000}
        placement={'bottom'}
        id={'aiAudio'}>
        <IconButton
          onClick={handleAudioPlay}
          sx={styles.button}
          disabled={
            isAudioMessageFetching ||
            isAudioMessagePlaying ||
            nextSession?.id === Number(sessionId ?? 0)
          }>
          {isPlaying ? (
            <Pause
              fontSize={'small'}
              htmlColor={theme.palette.common.surface['surface 46']}
            />
          ) : (
            <Sound
              fontSize={'small'}
              htmlColor={theme.palette.common.surface['surface 47']}
            />
          )}
        </IconButton>
      </Tooltip>
    </>
  );
};

export default AudioPlayer;
