import React, { FC } from 'react';

import { Box, Skeleton, Typography, useTheme } from '@mui/material';

import { styles } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentDataset } from '@bus/profile/selectors';
import ArrowDropDown from '@components/icons/ArrowDropDown';
import { modalActions } from '@core/Modal/state/actions';
import {
  getPopularNotProjects,
  getPopularProjects,
} from '@bus/projects/selectors';

type AppBarActionsProps = {
  isHaveButtons: boolean;
};

export const AppBarActions: FC<AppBarActionsProps> = ({ isHaveButtons }) => {
  const currentDataset = useSelector(getCurrentDataset);
  const dispatch = useDispatch();
  const theme = useTheme();
  const popularProjects = useSelector(getPopularProjects);
  const notPopularProjects = useSelector(getPopularNotProjects);
  const handleDatasets = () => {
    dispatch(
      modalActions.modal({
        component: 'MasterMenu',
        forceClose: false,
        variant: 'top',
        popularProjects,
        notPopularProjects,
      }),
    );
  };

  return (
    <Box
      sx={(theme) => styles.button(theme, isHaveButtons)}
      onClick={handleDatasets}>
      <Box>
        {currentDataset ? (
          React.createElement(currentDataset.icon || (() => <></>), {
            sx: { fontSize: '20px' },
            htmlColor: theme.palette.common.surface['surface 46'],
          })
        ) : (
          <Skeleton variant={'rounded'} width={'20px'} height={'20px'} />
        )}
        <Typography ml={'16px'} color={'text.secondary'} variant={'body1'}>
          {currentDataset?.name ? currentDataset.name : 'Explore Worlds'}
        </Typography>
      </Box>
      <ArrowDropDown
        sx={[styles.icon, styles.isOpen]}
        fontSize={'small'}
        htmlColor={theme.palette.text.secondary}
      />
    </Box>
  );
};

export default AppBarActions;
