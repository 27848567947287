import React, { FC } from 'react';

import { Box, Typography, useTheme } from '@mui/material';

import { styles } from './styles';

type PopularDatasetItemProps = {
  name: string;
  description: string;
  image: string | null;
  icon: any;
  action: () => void;
};

export const PopularDatasetItem: FC<PopularDatasetItemProps> = ({
  name,
  description,
  image,
  icon,
  action,
}) => {
  const theme = useTheme();

  return (
    <Box sx={styles.popularDatasetItem} onClick={action}>
      {image && (
        <Box component={'img'} alt={''} src={image} sx={styles.image} />
      )}
      <Box sx={styles.wrapper}>
        <Box display={'flex'} alignItems={'center'} gap={'8px'}>
          {React.createElement(icon, {
            sx: { fontSize: '20px' },
            htmlColor: theme.palette.common.surface['surface 46'],
          })}
          <Typography variant={'body2'} color={'common.brand'}>
            {name}
          </Typography>
        </Box>
        <Typography variant={'caption'} color={'text.secondary'}>
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

export default PopularDatasetItem;
